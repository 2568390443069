import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import LinearTextArea from "../../../components/TextArea";
import LinearTextBox from "../../../components/TextBox";
import drill from "../../../assests/icons/drill.svg";
import deleteIcon from "../../../assests/icons/ic/delete-1.svg";
import useSWR, { mutate } from "swr";
import useScreenSize from "../../../hooks/useScreenSize";
import Paginator from "../../../components/Paginator";
import { Alert, TextareaAutosize } from "@mui/material";

const PAGE_SIZE = 5;
const Notes = () => {
  const notesUrl = "user-data/notes";
  const { data: notesList } = useSWR(notesUrl);
  const [lowerRange, setLowerRange] = useState(0);
  const [upperRange, setUpperRange] = useState(PAGE_SIZE);

  // const [notesList, setNotesList] = useState([]);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteText, setNoteText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const { isMobile } = useScreenSize();

  // const getNotes = async () => {
  //   const { data } = await API.get("user-data", "notes");
  //   setNotesList(data);
  // };

  const deleteNote = async (id) => {
    const { status } = await API.post(`user-data/remove-note/${id}`, {});
    if (status === 200) {
      mutate(notesUrl);
    }
  };

  const handleTitleChange = (e) => {
    setNoteTitle(e.target.value);
  };
  const handleTextChange = (e) => {
    console.log(e.target.value);
    setNoteText(e.target.value);
  };

  const handleSubmit = async () => {
    if (noteTitle === "" || noteText === "") {
      setSeverity("error");
      setMessage("Please Enter All Fields");
      return;
    }
    const { status } = await API.post("user-data/add-note", {
      note_title: noteTitle,
      note_text: noteText,
    });

    if (status === 200) {
      setSeverity("success");
      setNoteText("");
      setNoteTitle("");
      setMessage("Note saved successfully!");

      mutate(notesUrl);
      setTimeout(() => {
        setMessage("");
        setShowModal(false);
      }, 2000);
    } else {
      setSeverity("error");
      setMessage("Internal error, Please Try Again Later.");
    }
  };

  // useEffect(() => {
  //   getNotes();
  // }, []);

  return (
    <div className="bg-white">
      <div className="flex flex-row justify-between items-center m-4">
        <h5></h5>
        <LinearButton text="New note" onClick={() => setShowModal(true)} />
      </div>
      <div
        className="flex flex-col items-center"
        style={{
          overflowY: "scroll",
          scrollBehavior: "smooth",
          maxHeight: "70vh",
        }}
      >
        <div>
          <Paginator
            setUpperRange={setUpperRange}
            setLowerRange={setLowerRange}
            pageContents={PAGE_SIZE}
            totalLength={notesList?.length}
          />
        </div>
        <div className="self-stretch">
          {notesList?.slice(lowerRange, upperRange)?.map((note, index) => {
            return (
              <div key={index} className="mx-4 border-b-2 py-4">
                <div className="flex flex-row justify-between items-center flex-wrap mb-4">
                  <div className="flex flex-row items-center justify-center gap-4 flex-wrap">
                    {isMobile ? (
                      <></>
                    ) : (
                      <img src={drill} style={{ width: "1.5rem" }} />
                    )}
                    <h6>{note.note_title}</h6>
                  </div>
                  <div className="ml-10 body-extra-small-1 flex gap-4 flex-row items-center ">
                    {new Date(note.added_date).getDate()} /{" "}
                    {new Date(note.added_date).getMonth() + 1} /{" "}
                    {new Date(note.added_date).getFullYear()}
                    <img
                      src={deleteIcon}
                      onClick={() => {
                        deleteNote(note.id);
                      }}
                      style={{ width: "0.90rem", cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-8 ml-10 body-medium-1 flex-wrap whitespace-pre-wrap	">
                  <div>{note.note_text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showModal && (
        <NewModal
          body={
            <div>
              <div className="text-center">
                <h5>Add a new note</h5>
              </div>
              <div className="flex flex-col items-center gap-4">
                <LinearTextBox
                  placeholder="Enter note title"
                  style={isMobile ? { width: "70vw" } : {}}
                  label={null}
                  onChange={handleTitleChange}
                />
                <TextareaAutosize
                  placeholder="Enter note text"
                  style={{
                    width: isMobile ? "70vw" : "80%",
                    minHeight: "6rem",
                    maxHeight: "12rem",
                    resize: "vertical",
                    padding: "0.5rem",
                    border: "1px solid #e5e7eb",
                    borderRadius: "0.375rem",
                  }}
                  onChange={(e) => setNoteText(e.target.value)}
                />

                <LinearButton text="Add" onClick={handleSubmit} />
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setMessage("");
            setShowModal(false);
            setNoteText("");
            setNoteTitle("");
          }}
        />
      )}
    </div>
  );
};

export default Notes;
