import React, { useEffect, useState } from "react";
import { API } from "../../api";
import "./index.css";
import ProgramContainer from "../../containers/Programs/ProgramContainer";
import useSWR from "swr";
import Collapsible from "../../components/CollapsibleHeader";
import { CircularProgress } from "@material-ui/core";

const Program = ({ enrolledGoals }) => {
  const [programList, setProgramList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const enrolledGoalIds =
    enrolledGoals?.map((userProgram) => userProgram?.id) || [];
  console.log(enrolledGoalIds);
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const org_id = localStorage.getItem("org_id");
        if (org_id !== "null" && org_id !== null) {
          const { data, status } = await API.get("org-program-access", org_id);
          if (status === 200) {
            setProgramList(data);
          }
        } else {
          const { data, status } = await API.get("program", "all-by-user-role");
          if (status === 200) {
            setProgramList(data);
          }
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  let heading = (
    <h4 className="text-center my-4 block sm:hidden">Select Program</h4>
  );

  const programContainerMapper = (program) => {
    return (
      <ProgramContainer
        isEnrolled={enrolledGoalIds.includes(program.id)}
        key={program.id}
        id={program.id}
        name={program.name}
        description={program.description}
        imageUrl={program.display_image_url}
        type={program.program_type}
      />
    );
  };

  const programListWrapper = (children) => {
    return (
      <div className="flex flex-row mt-4 flex-wrap justify-center gap-16 sm:gap-32">
        {children}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: "-20vh" }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  const programs = programListWrapper(
    programList.programs.map(programContainerMapper)
  );
  const sprints = programListWrapper(
    programList.sprints.map(programContainerMapper)
  );

  return (
    <div className="mb-8">
      {/* <Header /> */}
      <div className="flex flex-col sm:w-full">
        {heading}
        {/* <div className="programs-container"> */}
        {programList.sprints.length === 0 ? (
          <></>
        ) : (
          <Collapsible open={true} children={sprints} title="Sprints" />
        )}
        {programList.programs.length === 0 ? (
          <></>
        ) : (
          <Collapsible open={true} children={programs} title="Programs" />
        )}
      </div>
    </div>
  );
};

export default Program;
