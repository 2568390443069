import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "../../../../components/Table";
import { API } from "../../../../api";
import ActivityRowBox from "../../../../components/DashboardComponents/ActivityRowBox";

const AdminProgramSchedule = () => {
  const history = useHistory();
  const location = useLocation();

  const goal_id = location.state?.goal_id;
  const staticDay = location.state?.static_day;
  const program_name = location.state?.program_name;

  const { referrer } = location.state || {};

  const [userSchedule, setUserSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const goToActivity = ({ activity_id, name }) => {
    const referrer = location.pathname;
    history.push(`/admin/activity/preview/${activity_id}`, {
      goal_id: goal_id,
      activity_name: name,
      program_name,
      referrer: referrer,
    });
  };

  const getActivity = ({ activity_id, completed, name, enabled }) => {
    return (
      <div>
        <ActivityRowBox
          id={activity_id}
          completed={completed}
          enabled={enabled}
          message={name}
          key={activity_id}
          onClick={() =>
            (enabled || completed) && goToActivity({ activity_id, name })
          }
        />
      </div>
    );
  };

  const goToChallenge = ({ challenge_id, completedDate, completed }) => {
    history.push(`/challenge/id/${challenge_id}`, {
      goal_id: goal_id,
      completedDate,
      completed,
    });
  };

  const getChallenge = ({ challenge_id, completed, name, enabled }) => {
    return (
      <div>
        <ActivityRowBox
          id={challenge_id}
          completed={completed}
          enabled={false}
          message={name}
          key={challenge_id}
          onClick={() =>
            (enabled || completed) &&
            goToChallenge({ challenge_id, date: new Date(), completed })
          }
        />
      </div>
    );
  };

  useEffect(() => {
    if (userSchedule.length === 0) return;

    // Group activities and challenges by day_id
    const groupedData = userSchedule.reduce((acc, curr) => {
      const dayId = curr.day_id;

      // If the dayId doesn't exist, initialize the object
      if (!acc[dayId]) {
        acc[dayId] = { dayId, activities: [], challenges: [] };
      }

      // Add activity if exists
      if (curr.activity) {
        acc[dayId].activities.push({
          id: curr.activity.id,
          name: curr.activity.name,
          enabled: curr.active,
          completed: curr.completed, // Assuming you have completion info
          dayId: curr.day_id,
        });
      }

      // Add challenge if exists
      if (curr.challenge) {
        acc[dayId].challenges.push({
          id: curr.challenge.id,
          name: curr.challenge.name,
          enabled: curr.active,
          completed: curr.completed, // Assuming you have completion info
          dayId: curr.day_id,
        });
      }

      return acc;
    }, {});

    // Prepare table data by extracting values from groupedData
    const tableData = Object.values(groupedData).map((group) => {
      const dayNumber = group.dayId;
      const enabled = true;

      // Get activities and challenges
      const activities = group.activities.map((act) =>
        getActivity({
          activity_id: act.id,
          completed: act.completed,
          day_id: act.dayId,
          name: act.name,
          enabled,
        })
      );

      const challenges = group.challenges.map((chal) =>
        getChallenge({
          challenge_id: chal.id,
          completed: chal.completed,
          day_id: chal.dayId,
          name: chal.name,
          enabled,
        })
      );

      return [dayNumber, activities, challenges];
    });

    // Set the table header and body
    setTableHeader(["Day", "Learn", "Challenge"]);
    setTableBody(tableData);
  }, [userSchedule, staticDay]);

  const getUserSchedule = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await API.get(
        `static-schedule/goal-schedule`,
        goal_id
      );

      setUserSchedule(data);
    } catch (error) {
      console.error("Failed to fetch user schedule", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserSchedule();
  }, []);

  return (
    <div>
      <div className="bg-gray flex justify-between h-14 mt-0">
        <div className="text-center my-4 text-gray-500 mt-4 ml-2">
          <button
            onClick={() => {
              history.push(referrer);
            }}
            className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
          >
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ArrowBackIcon />
            </svg>
            <p className="text-gray-800">Back</p>
          </button>
        </div>
        <div className="flex text-center my-4 text-gray-500 mt-8">
          <div className="flex space-x-1 bg-transparent items-center p-2 bg-white">
            <p className="text-gray-800 text-xl">{program_name}</p>
          </div>

          {/* <button
            onClick={() => setShowAddBulkUserModal(true)}
            className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
          >
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <AddMultipleUser />
            </svg>
            <p className="text-gray-800">Add multiple users</p>
          </button> */}
        </div>
        <div className="text-center my-4 text-gray-500 mt-0">
          {/* <button
              onClick={handleExport}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer hover:bg-gray-100 hover:text-white transition-all duration-200"
            >
              <svg
                className="w-6 h-6"
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <ExportIcon />
              </svg>
              <p className="text-gray-800">Export</p>
            </button> */}
        </div>
      </div>

      <div className="mx-2 rounded-xl p-2 mt-2">
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress size={80} />
          </div>
        ) : (
          <Table header={tableHeader} body={tableBody} />
        )}
      </div>
    </div>
  );
};

export default AdminProgramSchedule;
