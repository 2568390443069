import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import linear_logo from "../../assests/images/linear_logo.png";
import default_profile_pic from "../../assests/images/default_profile_pic.png";
import linear_text from "../../assests/images/linear_text.png";
import linear_logo_text from "../../assests/icons/logos/1.svg";
import SearchIcon from "@mui/icons-material/Search";
import NotificationIcon from "@material-ui/icons/Notifications";
import NotificationNoneIcon from "@material-ui/icons/NotificationsNone";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import book_stack from "../../assests/icons/daily_challenge.svg";
import { useOnClickOutside } from "../../hooks/useClickOutside";
import { API } from "../../api";
import { useLocation } from "react-router";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link, useHistory } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";
import BurgerIcon from "@material-ui/icons/Menu";
import CancelIcon from "@material-ui/icons/ClearOutlined";
import useBodyScrollToggle from "../../hooks/useBodyScrollToggle";
import { Tooltip } from "@mui/material";
import SearchEngine from "../SearchEngine/SearchEngine";

const ResponsiveHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [areNewNotifications, setAreNewNotifications] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showAdminHub, setShowAdminHub] = useState(false);
  // const user = useStoreState((s) => s.user.data);
  const history = useHistory();
  const deleteUser = useStoreActions((a) => a.user.delete);
  const location = useLocation();
  const userFirstName = localStorage.getItem("first_name");
  const userLastName = localStorage.getItem("last_name");
  // const userNewPhotoURL = localStorage.getItem("newPhotoURL");
  const userData = useStoreState((state) => state.user.data);
  const userRoleId = userData?.role_id;
  const userNewPhotoURL = userData?.newPhotoURL;

  const [showSearchEngine, setShowSearchEngine] = useState(false);

  const { isMobile } = useScreenSize();

  const getNotifications = async () => {
    if (userFirstName) {
      const { data } = await API.get("notification", "get-inapp?limit=5");
      setNotificationList(data);
      return data;
    }
  };

  useEffect(() => {
    if ([2, 3, 4, 5, 6].includes(userRoleId)) {
      setShowAdminHub(true);
    } else {
      setShowAdminHub(false);
    }
  }, [userRoleId]);

  useEffect(() => {
    if (showMenu && !isMobile) {
      setShowMenu(false);
    }
  }, [isMobile]);

  const notificationRef = useRef();
  const userMenuRef = useRef();
  const drawerRef = useRef();

  useOnClickOutside(userMenuRef, () => setShowMenu(false));
  useOnClickOutside(notificationRef, () => setShowNotifications(false));
  useBodyScrollToggle({ allowScroll: !showMenu });

  const logoutUser = async () => {
    localStorage.clear();
    sessionStorage.clear();
    deleteUser();
    // Call API to clear cookie
    const { status } = await API.post("user/logout");
    if (status === 200) {
      history.push("/user/login");
    }
  };

  const HeaderBlacklist = [
    "login",
    "admin",
    "signup",
    "forgot-password",
    "reset-password",
    "confirm",
    "cb",
    "activity",
    "post-login",
  ];
  const blackListedPath = HeaderBlacklist.find((path) => {
    return location.pathname.split("/").includes(path);
  });

  if (blackListedPath) return null;
  if (location.pathname.split("/").includes("admin")) return null;

  return (
    <div className="sticky top-0 m-0" style={{ zIndex: 9999 }}>
      <nav
        className="bg-white shadow-lg text-white"
        style={{ backgroundColor: "#003366" }}
      >
        <div className={isMobile ? "px-4" : "px-48"}>
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <Link to="/user/dashboard">
                <div className="flex flex-row justify-center items-center">
                  <img alt="Linear Logo" src={linear_logo} className="h-10" />
                  <img alt="Linear Text" src={linear_text} className="h-16" />
                </div>
              </Link>
            </div>
            <div className="hidden md:flex items-center space-x-3 ">
              {!userFirstName && (
                <div>
                  <Link to="/user/login">Log in</Link>
                </div>
              )}
              <div>
                <Link to="/programs">Programs</Link>
              </div>
              <div className="cursor-pointer">
                {process.env.REACT_APP_ENV === "development" && <Tooltip
                  title="Search Completed Activities"
                  arrow
                  placement="top"
                >
                  <SearchIcon
                    onClick={() => {
                      // console.log("Search Clicked");
                      setShowSearchEngine(true); // Open the search modal
                    }}
                  />
                </Tooltip>}
              </div>
              {userFirstName &&
                !location.pathname.split("/").includes("activity") && (
                  <div className="flex flex-row items-center my-auto gap-4">
                    <div>
                      {areNewNotifications ? (
                        <NotificationIcon
                          onClick={() => {
                            getNotifications();
                            // readNotifications();
                            setShowNotifications(!showNotifications);
                          }}
                        />
                      ) : (
                        <NotificationNoneIcon
                          onClick={() => {
                            getNotifications();
                            setShowNotifications(!showNotifications);
                          }}
                        />
                      )}
                      {/* <NotificationNoneIcon  /> */}
                      {showNotifications && (
                        <div
                          ref={notificationRef}
                          className="absolute right-36 text-black bg-white rounded-xl"
                        >
                          {notificationList?.map((notification, index) => {
                            return (
                              <div key={index} className="border-b-2 px-4 py-2">
                                <div className="body-extra-small-1 flex flex-row flex-start  gap-4">
                                  {/* <img src={ellipse_blue} className="image" /> */}
                                  <div className="overlay">
                                    <img alt="book_stack"src={book_stack} />
                                  </div>
                                  {notification?.message}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          setShowUserMenu((userMenu) => !userMenu);
                        }}
                        // ref={userMenuRef}
                        className="cursor-pointer flex flex-row justify-center gap-2 items-center"
                      >
                        <img
                          alt="profile_pic"
                          style={{
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            minHeight: 40,
                            marginRight: 10,
                            borderRadius: "50%",
                            behavior: "url(PIE.htc)",
                          }}
                          src={
                            userNewPhotoURL
                              ? userNewPhotoURL
                              : default_profile_pic
                          }
                        />
                        {userFirstName} {userLastName}
                        {showUserMenu ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                      {showUserMenu && (
                        <div
                          className="absolute right-18 text-black bg-white w-36 rounded-xl"
                          style={{
                            backgroundColor: "#e9f3fd",
                            color: "#2288EE",
                            // color "white",
                          }}
                          onClick={() => {
                            setShowUserMenu(false);
                          }}
                        >
                          <Link to="/profile">
                            <div className="p-3 border-b-2 cursor-pointer border-blue-300">
                              Profile
                            </div>
                          </Link>
                          {/* <Link to="/profile" params={{ component: "journal" }}>
                            <div className="p-4 border-b-2 cursor-pointer">
                              Journal
                            </div>
                          </Link> */}
                          <div
                            className="p-3 border-b-2 cursor-pointer border-blue-300"
                            onClick={() => {
                              history.push("/profile", {
                                component: "journal",
                              });
                            }}
                          >
                            Journal
                          </div>
                          {showAdminHub && (
                            <div
                              className="p-3 border-b-2 cursor-pointer border-blue-300"
                              onClick={() => {
                                history.push("/administrator", {
                                  component: "reports",
                                });
                              }}
                            >
                              Administrator
                            </div>
                          )}
                          <Link to="/support">
                            <div className="p-3 border-b-2 cursor-pointer border-blue-300">
                              Support
                            </div>
                          </Link>

                          <Link to="/user/login">
                            <div
                              className="p-3 cursor-pointer border-blue-300"
                              onClick={logoutUser}
                            >
                              Logout
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>

            <div className="md:hidden flex items-center">
              <button>
                {showMenu ? (
                  <CancelIcon
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  />
                ) : (
                  <BurgerIcon
                    onClick={() => {
                      setShowMenu(true);
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            ref={drawerRef}
            style={{
              transition: "all 0.2s linear",
              width: "75%",
              transform: showMenu ? "translateX(0%)" : "translateX(-110%)",
              zIndex: 9999,
            }}
            className="text-black h-screen bg-white absolute top-0 transition transform sm:hidden"
            // className="text-center text-black bg-white transform ease-out duration-700"
            // style={{
            //   display: showMenu ? "block" : "none",
            //   // opacity: showMenu ? 100 : 0,
            //   // height: showMenu ? "fit-content" : 0,
            //   // transitionDuration: 300,
            //   // transition: "",
            // }}
          >
            <ul className="mt-2 ">
              <div className="flex flex-row items-center gap-2 color-gray-200 text-xl">
                <img
                  alt="linear_logo_text"
                  src={linear_logo_text}
                  className="h-24 "
                />
                {/* <h3>Linear</h3> */}
              </div>
              <li>
                <Link to="/profile" onClick={() => setShowMenu(false)}>
                  <div className="p-4 border-b-2 cursor-pointer">Profile</div>
                </Link>
              </li>
              <li>
                <Link to="/programs" onClick={() => setShowMenu(false)}>
                  <div className="p-4 border-b-2 cursor-pointer">Programs</div>
                </Link>
              </li>

              <li>
                <Link to="/journal" onClick={() => setShowMenu(false)}>
                  <div className="p-4 border-b-2 cursor-pointer">Journal</div>
                </Link>
              </li>
              <li>
                <Link to="/support" onClick={() => setShowMenu(false)}>
                  <div className="p-4 border-b-2 cursor-pointer">Support</div>
                </Link>
              </li>
              {/* {deferredPrompt && (
                <li>
                  <div
                    className="p-4 border-b-2 cursor-pointer"
                    onClick={async () => {
                      if (deferredPrompt !== null) {
                        deferredPrompt.prompt();
                        const { outcome } = await deferredPrompt.userChoice;
                        if (outcome === "accepted") {
                          deferredPrompt = null;
                        }
                      }
                    }}
                  >
                    Install App
                  </div>
                </li>
              )} */}
              <li>
                <div>
                  <Link to="/user/login">
                    <div
                      className="p-4 cursor-pointer"
                      onClick={() => {
                        setShowMenu(false);
                        logoutUser();
                      }}
                    >
                      Logout
                    </div>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Search Engine */}
      <div>
        <SearchEngine open={showSearchEngine} setOpen={setShowSearchEngine} />
      </div>
    </div>
  );
};

export default ResponsiveHeader;
