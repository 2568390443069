import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AdminAddProgram from "./Add";
import AdminProgramList from "./List";
import AdminProgramSchedule from "./Schedule/AdminProgramSchedule";

const AdminProgramRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/program/list" component={AdminProgramList} />
      <Route
        exact
        path="/admin/program/schedule"
        component={AdminProgramSchedule}
      />
      <Route exact path="/admin/program/add" component={AdminAddProgram} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminProgramRoutes;
