import React, { useEffect, useState } from "react";
import "./index.css";
import AdminActivityList from "../Activity/List";
import AdminProgramList from "../Program/List";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminDashboard from "../AdminDashboard/index";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "Program",
    value: "program",
  },
  {
    index: 1,
    text: "Activity",
    value: "activity",
  },
];

const MENU_INDEX = ["program", "activity"];

const CurriculumSideBar = (props) => {
  const [component, setComponent] = useState("program");
  const [programComponent, setProgramComponent] = useState("viewprogram");
  const [activityComponent, setActivityComponent] = useState("viewactivity");
  const [selectedButton, setSelectedButton] = useState(0);

  const displayProgramComponent = () => {
    if (programComponent === "viewprogram") return <AdminProgramList />;
  };

  const displayActivityComponent = () => {
    if (activityComponent === "viewactivity") return <AdminDashboard />;
  };

  const displayComponent = () => {
    if (component === "program") {
      return (
        <div>
          {/* <div className="flex flex-row gap-16  mx-4 my-4">
            <div
              className="cursor-pointer"
              onClick={() => setProgramComponent("viewprogram")}
              style={
                programComponent === "viewprogram"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              View Program
            </div>
          </div> */}
          {displayProgramComponent()}
        </div>
      );
    } else if (component === "activity") {
      return (
        <div>
          <div className="flex flex-row gap-16  mx-4 my-4">
            <div
              className="cursor-pointer"
              onClick={() => setActivityComponent("viewteam")}
              style={
                activityComponent === "viewactivity"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              View Activity
            </div>
          </div>
          {displayActivityComponent()}
        </div>
      );
    }
  };

  useEffect(() => {
    const propsProgramComponent = props.location?.state?.component;
    const propsProgramJournal = props.location?.state?.programComponent;
    if (propsProgramComponent) {
      setComponent(propsProgramComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsProgramComponent));
    }
    if (propsProgramJournal) setProgramComponent(propsProgramJournal);

    const propsActivityComponent = props.location?.state?.component;
    const propsActivityJournal = props.location?.state?.activityComponent;
    if (propsActivityComponent) {
      setComponent(propsActivityComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsActivityComponent));
    }
    if (propsActivityJournal) setActivityComponent(propsActivityJournal);
  }, []);

  return (
    <div>
      <AdminHeader />
      <div
        className="min-h-content min-h-screen"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div className="flex flex-row">
          <div className="p-4 m-4 bg-white h-full container md:w-1/3 lg:w-1/5">
            <div>
              {MENU_OPTIONS.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="body-small-1 p-4 mb-4 cursor-pointer"
                    onClick={() => {
                      setComponent(option.value);
                      setSelectedButton(index);
                    }}
                    style={
                      index === selectedButton
                        ? {
                            backgroundColor: "#E9F3FD",
                            color: "#2288EE",
                            borderRadius: "10px",
                          }
                        : null
                    }
                  >
                    <div className="flex flex-row items-center gap-4">
                      {/* <img
                        src={
                          selectedButton === index
                            ? option.selected_img
                            : option.img
                        }
                      /> */}
                      {option.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="p-4 my-4 mr-4 bg-white h-full container overflow-auto">
            {component === "organization" && (
              <div className="mx-4">
                <h5>Program</h5>
              </div>
            )}
            {displayComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurriculumSideBar;
