import React, { useState, useEffect } from "react";
import drill from "../../../assests/icons/drill.svg";
import daily_challenge from "../../../assests/icons/daily_challenge.svg";
import day_challenge from "../../../assests/icons/day_challenge.svg";
import { useHistory } from "react-router-dom";
import useScreenSize from "../../../hooks/useScreenSize";
import { CircularProgress } from "@material-ui/core";
import { API } from "../../../api";
import Check from "@material-ui/icons/Check";
import Cross from "@material-ui/icons/Clear";
import ActivityRowBox from "../ActivityRowBox";
import SendChallenge from "../../../views/Challenge/SendChallengeForReview/SendChallenge";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

const ChallengeList = (props) => {
  const [challengeList, setchallengeList] = useState([]);
  const [loader, toggleLoader] = useState(false);

  useEffect(() => {
    toggleLoader(true);
    const fetchData = async () => {
      const { data, status } = await API.get(
        "challenge",
        `day-list/${props.dayAdjust}?program_id=${props.selectedProgramID}`
      );
      if (status === 200) setchallengeList(data);
      toggleLoader(false);
    };
    if (!props.waitForUnpause) {
      fetchData();
    }
  }, [props.dayAdjust, props.selectedProgramID, props.waitForUnpause]);

  const history = useHistory();
  const { isMobile } = useScreenSize();

  const goToChallenge = (challenge, completedDate, completed) => {
    history.push(`/challenge/id/${challenge.challenge_id}`, {
      goal_id: challenge.goal_id,
      completedDate,
      completed,
    });
  };

  return (
    <div>
      <div>
        {loader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        {challengeList?.isWeekend && (
          <ActivityRowBox message="No Challenges for Today!" missed={true} />
        )}
        {challengeList?.challengeList?.length === 0 &&
          !challengeList?.isWeekend && (
            <ActivityRowBox
              message="We are unable to fetch Challenges!"
              missed={true}
            />
          )}

        {challengeList?.challengeList?.map((challenge, index) => {
          const startTime = challenge.schedule?.start_time;
          const endTime = challenge.schedule?.end_time;
          const completedDate = challenge.schedule?.completed_date;
          const completed = challenge.completed;
          const showScheduleTime = startTime && endTime;
          const scheduleText =
            "Scheduled for " +
            new Date(startTime).toLocaleString() +
            " to " +
            new Date(endTime).toLocaleTimeString();

          return (
            <div
              style={{
                backgroundColor: "#FFFFFF",
                color:
                  completed && completedDate
                    ? "var(--success)"
                    : challenge.started
                    ? "#7B61FF"
                    : challenge?.enabled
                    ? "#2288EE"
                    : challenge.missed
                    ? "var(--error)"
                    : "#B9B9BA",
                width: isMobile ? "95vw" : "",
              }}
              className="task-option task-text cursor-pointer gap-4"
              key={index}
              // style={{ }}
            >
              <div>
                {!(completed && completedDate) &&
                  !challenge.started &&
                  !challenge.missed && (
                    <img
                      alt="challenge_type"
                      src={
                        challenge.challenge?.type === "daily"
                          ? day_challenge
                          : challenge.challenge?.type === "challenge"
                          ? daily_challenge
                          : drill
                      }
                    />
                  )}
                {challenge.started && !(completed && completedDate) ? (
                  <svg className="w-5 h-5" style={{ color: "#7B61FF" }}>
                    <HourglassTopIcon />
                  </svg>
                ) : null}
                {completed && completedDate ? (
                  <svg className="w-5 h-5">
                    <Check />
                  </svg>
                ) : null}

                {challenge.missed ? <Cross className="-ml-4" /> : null}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <div
                  onClick={() =>
                    ((completed && completedDate) || challenge.enabled) &&
                    goToChallenge(challenge, completedDate, completed)
                  }
                >
                  {challenge.challenge?.name}
                </div>
              </div>
              <div
                className="body-small-2"
                // style={{
                //   background:
                //     index % 3 === 0
                //       ? "#F4F2FE"
                //       : index % 2 === 0
                //       ? "#FFF5F2"
                //       : "#E9FDEF",
                //   color:
                //     index % 3 === 0
                //       ? "#7B61FF"
                //       : index % 2 === 0
                //       ? "#EC7356"
                //       : "#2BAC52",
                // }}
              >
                {completed && completedDate ? (
                  <SendChallenge
                    challenge_id={challenge.challenge_id}
                    goal_id={challenge.goal_id}
                    challenge_name={challenge.challenge?.name}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChallengeList;
