import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../api";
import DisplayReport from "../../../components/Table/DisplayReport";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const RunReport = () => {
  const history = useHistory();
  const location = useLocation();
  const url = location.state.viewLink;
  const email = localStorage.getItem("email");

  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async (url) => {
    const { data } = await API.get(`${url}`, "");
    const filterData = data.filter((user) => user.Email !== email);
    setReportData(filterData);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(url);
  }, []);

  return (
    <div
      className="min-h-content min-h-screen flex"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <div className="p-4 m-2 bg-white h-full h-screen w-full overflow-auto">
        <div className="flex justify-between">
          <div className="text-center my-4 text-gray-500 mt-0">
            <button
              onClick={() => {
                history.push("/administrator", {
                  component: "reports",
                });
              }}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
            >
              <svg
                className="w-6 h-6 text-blue-400 fill-current "
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <ArrowBackIcon />
              </svg>
              <p className="text-gray-800">Back</p>
            </button>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className="flex justify-center items-center h-screen">
              <CircularProgress size={80} />
            </div>
          ) : reportData.length ? (
            <DisplayReport data={reportData} email={email} />
          ) : (
            <div class="flex justify-center items-start min-h-screen bg-white ">
              <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
                <p class="text-3xl font-bold text-gray-500 mb-4">
                  There is no data available matching the conditions for this
                  report🦉!
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RunReport;
