import React, { useEffect, useState } from "react";
import ReportTable from "../../../components/Table/ReportTable";

function Reports() {
  const [tableBody, setTableBody] = useState([]);

  function getGroupAdminId() {
    const role_id = parseInt(localStorage.getItem("role_id"), 10);
    var role = "org_admin";
    if (role_id === 2) {
      role = "group_admin";
    }
    setTableBody([
      {
        reportName: "User Yet to Register",
        description: "Users who have been invited but not registered yet",
        viewLink: `reports/unregistered-users/${role}`,
      },
      {
        reportName: "Registered Users Yet to Start",
        description: "Users who have registered but not started yet",
        viewLink: `reports/unstarted-users/${role}`,
      },
      {
        reportName: "Users Inactive for more than 'x' No of Days",
        description: "Users who have been inactive for more than 'x' days. ",
        viewLink: `reports/inactive-users/${role}/3`,
      },
      {
        reportName: "Basic Metrics for days",
        description:
          "Days, Activities & Challenges completed metrics and last active date",
        viewLink: `reports/basic-metrics/${role}`,
      },
      {
        reportName: "Program Completion",
        description: "Program Completion Report",
        viewLink: `reports/program-completion/${role}`,
      },
      {
        reportName: "Users Completed Entire Program",
        description: "Users Who have Completed Entire program",
        viewLink: `reports/users-completed-entire-program/${role}`,
      },
      {
        reportName: "Users Completed All Learn Activities",
        description: "Users Who have Completed All Learn Activities",
        viewLink: `reports/users-completed-all-learn-activities/${role}`,
      },
    ]);
  }
  useEffect(() => {
    getGroupAdminId();
  }, []);

  const tHead = ["Report", "Description", "Actions"];
  const tBody = tableBody;

  return (
    <div>
      <ReportTable header={tHead} body={tBody} />
    </div>
  );
}

export default Reports;
