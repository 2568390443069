import React, { useState, useEffect } from "react";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import Table from "../../../components/Table";
import useScreenSize from "../../../hooks/useScreenSize";
import ProgramCard from "../ProgramCard";
import LottieAnimation from "./LottieAnimation/LottieAnimation";
import { IconButton, Modal, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProgramCompletionCertificateImage from "../../../assests/images/program_certificate.png";
import SprintCompletionCertificateImage from "../../../assests/images/sprint_certificate.png";
import LearnCompletionCertificateImage from "../../../assests/images/learn_certificate.png";
import PlayIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/PauseCircleOutline";
import QuitIcon from "@mui/icons-material/HighlightOff";
import ExploreIcon from "@mui/icons-material/Explore";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ProgramCompletionIcon from "@mui/icons-material/School";
import LearnActivityIcon from "@mui/icons-material/SportsScore";
import { useHistory, useLocation } from "react-router-dom";

const MyPrograms = () => {
  const [enrolledGoals, setEnrolledGoals] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showQuitModal, setShowQuitModal] = useState(false);

  const [action, setAction] = useState("");
  const [selectedProgramID, setSelectedProgramID] = useState();
  const { isMobile } = useScreenSize();

  const history = useHistory();
  const location = useLocation();

  const generateProgramCompletionCertificate = ({
    program,
    completedDate,
    programType,
  }) => {
    const fName = localStorage.getItem("first_name");
    const lName = localStorage.getItem("last_name");
    const fullName = `${fName} ${lName}`;

    const generateProgramCertificate = () => {
      const username = fullName;
      const programName = program.toUpperCase();
      const date = new Date(completedDate);
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC",
      };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const certificateImageElement = document.getElementById(
        "program-certificate-image"
      );

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = certificateImageElement.width;
      canvas.height = certificateImageElement.height;

      context.drawImage(certificateImageElement, 0, 0);

      // Position and size of the username text
      const usernameX = 100;
      const usernameY = 535;
      const usernameFontSize = 75;
      context.font = `bold ${usernameFontSize}px Arial`;
      context.fillStyle = "black";
      context.fillText(username, usernameX, usernameY);

      //Position and size of the program name text
      const programNameX = 100;
      const programNameY = 855;
      const programNameFontSize = 50;
      context.font = `bold ${programNameFontSize}px Arial`;
      context.fillStyle = "black";
      context.fillText(programName, programNameX, programNameY);

      //Position and size of the program name text
      const dateOfCompletionX = 95;
      const dateOfCompletionY = 1115;
      const dateOfCompletionSize = 45;
      context.font = `bold ${dateOfCompletionSize}px Arial`;
      context.fillStyle = "black";
      context.fillText(formattedDate, dateOfCompletionX, dateOfCompletionY);

      const dataURL = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = dataURL;
      link.download = `${programName}.png`;
      link.click();
    };

    return (
      <div>
        <button onClick={generateProgramCertificate}>
          <Tooltip
            title="Download Program Completion Certificate"
            arrow
            placement="top"
          >
            <svg
              className="w-8 h-8"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ProgramCompletionIcon />
            </svg>
          </Tooltip>
        </button>
        <img
          id="program-certificate-image"
          src={
            programType === "program"
              ? ProgramCompletionCertificateImage
              : SprintCompletionCertificateImage
          }
          alt="Certificate"
          crossOrigin="anonymous"
          style={{ display: "none" }}
        />
      </div>
    );
  };

  const generateLearnCompletionCertificate = ({ program, completedDate }) => {
    const fName = localStorage.getItem("first_name");
    const lName = localStorage.getItem("last_name");
    const fullName = `${fName} ${lName}`;

    const generateLearnCertificate = () => {
      const username = fullName;
      const programName = program.toUpperCase();
      const date = new Date(completedDate);
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC",
      };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const certificateImageElement = document.getElementById(
        "learn-certificate-image"
      );

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = certificateImageElement.width;
      canvas.height = certificateImageElement.height;

      context.drawImage(certificateImageElement, 0, 0);

      // Position and size of the username text
      const usernameX = 100;
      const usernameY = 535;
      const usernameFontSize = 75;
      context.font = `bold ${usernameFontSize}px Arial`;
      context.fillStyle = "black";
      context.fillText(username, usernameX, usernameY);

      //Position and size of the program name text
      const programNameX = 100;
      const programNameY = 855;
      const programNameFontSize = 50;
      context.font = `bold ${programNameFontSize}px Arial`;
      context.fillStyle = "black";
      context.fillText(programName, programNameX, programNameY);

      //Position and size of the program name text
      const dateOfCompletionX = 95;
      const dateOfCompletionY = 1115;
      const dateOfCompletionSize = 45;
      context.font = `bold ${dateOfCompletionSize}px Arial`;
      context.fillStyle = "black";
      context.fillText(formattedDate, dateOfCompletionX, dateOfCompletionY);

      const dataURL = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = dataURL;
      link.download = `${programName}.png`;
      link.click();
    };

    return (
      <div>
        <button onClick={generateLearnCertificate}>
          <Tooltip
            title="Download Learn Completion Certificate"
            arrow
            placement="top"
          >
            <svg
              className="w-8 h-8"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <LearnActivityIcon />
            </svg>
          </Tooltip>
        </button>
        <img
          id="learn-certificate-image"
          src={LearnCompletionCertificateImage}
          alt="Certificate"
          crossOrigin="anonymous"
          style={{ display: "none" }}
        />
      </div>
    );
  };

  const getProgressBar = (progress) => {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" value={progress} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const QuitButton = ({ disabled, goal_id }) => (
    <button
      disabled={disabled}
      onClick={() => {
        setShowQuitModal(true);
        setAction("Quit");
        setSelectedProgramID(goal_id);
      }}
    >
      <Tooltip title="Quit Program" arrow placement="top">
        <svg
          className="w-8 h-8"
          style={{ color: "#2288EE", fill: "#2288EE" }}
          viewBox="0 0 20 20"
        >
          <QuitIcon />
        </svg>
      </Tooltip>
    </button>
  );

  const ExploreButton = ({ goal_id, static_day, program_name }) => {
    return (
      <button
        onClick={() => {
          const referrer = location.pathname;
          history.push("/user/program-schedule", {
            goal_id: goal_id,
            static_day,
            program_name,
            referrer: referrer,
          });
        }}
      >
        <Tooltip title="Explore Program Schedule" arrow placement="top">
          <svg
            className="w-8 h-8"
            style={{ color: "#2288EE", fill: "#2288EE" }}
            viewBox="0 0 20 20"
          >
            <ExploreIcon />
          </svg>
        </Tooltip>
      </button>
    );
  };

  const playPauseButton = ({ paused, goal_id }) => (
    <button
      onClick={() => {
        setShowPauseModal(true);
        setAction(paused ? "Resume" : "Pause");
        setSelectedProgramID(goal_id);
      }}
      aria-label={paused ? "Resume" : "Pause"}
    >
      {paused ? (
        <Tooltip title="Play/Pause Program" arrow placement="top">
          <svg
            className="w-8 h-8"
            style={{ color: "#2288EE", fill: "#2288EE" }}
            viewBox="0 0 20 20"
          >
            <PlayIcon />
          </svg>
        </Tooltip>
      ) : (
        <Tooltip title="Play/Pause Program" arrow placement="top">
          <svg
            className="w-8 h-8"
            style={{ color: "#2288EE", fill: "#2288EE" }}
            viewBox="0 0 20 20"
          >
            <PauseIcon />
          </svg>
        </Tooltip>
      )}
    </button>
  );

  const handlePause = async () => {
    if (action === "Pause") {
      const { status } = await API.post(`goal/pause/${selectedProgramID}`, {});
      if (status === 200) {
        getEnrolledGoals();
        setShowPauseModal(false);
      }
    } else if (action === "Resume") {
      const { status } = await API.post(`goal/resume/${selectedProgramID}`, {});
      if (status === 200) {
        getEnrolledGoals();
        setShowPauseModal(false);
      }
    }
  };

  const handleQuit = async () => {
    const { status } = await API.post(`goal/quit/${selectedProgramID}`, {});
    if (status === 200) {
      getEnrolledGoals();
      setShowQuitModal(false);
    }
  };

  async function getEnrolledGoals() {
    const { data, status } = await API.get("goal", "enrolled-goals");
    if (status === 200) {
      setEnrolledGoals(data);
    }
  }

  useEffect(() => {
    const tableData = [];
    for (const goal of enrolledGoals) {
      // const completionStatus = <LottieAnimation progress={progress} />;
      const goalName = goal?.goal?.name;
      const programName = goal?.goal?.program?.name;
      const programProgress = goal?.completion_percentage;
      const programType = goal?.goal?.program?.program_type;
      const learnActivityCompletion =
        Number(goal?.learn_completion_percentage) ?? 0;
      const completed = goal?.completed;
      // const learnCompletionStatus = getProgressBar(
      //   Number(goal?.learn_completion_percentage) ?? 0
      // );
      const programCompletionStatus = getProgressBar(Number(programProgress));
      const element = [
        programName,
        goalName,
        // learnCompletionStatus,
        programCompletionStatus,
        <div className="flex flex-row gap-4">
          {ExploreButton({
            goal_id: goal?.goal_id,
            static_day: goal?.static_schedule_day_number,
            program_name: programName,
          })}
          {/* {goal?.learn_completion_date &&
            learnActivityCompletion === 100 &&
            generateLearnCompletionCertificate({
              program: goal?.goal?.program?.name,
              completedDate: goal?.learn_completion_date,
            })} */}
          {programProgress === "100" || completed === true ? (
            generateProgramCompletionCertificate({
              program: goal?.goal?.program?.name,
              completedDate: goal?.completed_date,
              programType: programType,
            })
          ) : (
            <>
              {!goal.quitted
                ? playPauseButton({
                    paused: goal.paused,
                    goal_id: goal.goal_id,
                  })
                : null}
              {QuitButton({ disabled: goal.quitted, goal_id: goal.goal_id })}
            </>
          )}
        </div>,
      ];
      tableData.push(element);
    }
    setTableHeader([
      "Program",
      "Goal",
      // "Learn Modules",
      "Overall Program",
      "Actions",
    ]);
    setTableBody(tableData);
  }, [enrolledGoals]);

  useEffect(() => {
    getEnrolledGoals();
  }, []);

  return (
    <div className={isMobile ? "bg-gray-200 min-h-screen " : ""}>
      <div className="p-4 my-4 bg-white rounded-lg overflow-auto mx-2">
        {isMobile &&
          enrolledGoals?.map((goal, index) => {
            return (
              <ProgramCard
                key={index}
                programName={goal.goal?.program?.name}
                goalName={goal.goal?.name}
                actions={[
                  <div className="flex flex-row gap-4 my-2">
                    {QuitButton({
                      disabled: goal.quitted,
                      goal_id: goal.goal_id,
                    })}
                    {!goal.quitted
                      ? playPauseButton({
                          paused: goal.paused,
                          goal_id: goal.goal_id,
                        })
                      : null}
                  </div>,
                ]}
              />
            );
          })}
        <Table header={tableHeader} body={tableBody} />
      </div>
      {/* <div className="flex flex-col mt-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 body-small-2">
                  <tr>
                    <th
                      scope="col"
                      className="px-8 py-3 text-left text-xs font-medium text-gray-500 body-small-2 tracking-wider"
                    >
                      Program
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 body-small-2 tracking-wider"
                    >
                      Goal
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 body-small-2 tracking-wider"
                    >
                      {" "}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 body-small-2 tracking-wider"
                    >
                      {" "}
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 body-small-1">
                  {enrolledGoals.map((goal, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10"></div>
                            <div className="">
                              <div className="text-sm font-medium flex items-center justify-center text-gray-900 body-small-1">
                                <div>{goal.goal?.program?.name}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 body-small-1">
                            {goal.goal.name}
                          </div>
                        </td>
                     
                        <td className="px-6 py-4 whitespace-nowrap flex gap-4  text-sm text-gray-500 body-small-1">
                          <LinearButton
                            text="Quit"
                            disabled={goal.quitted}
                            onClick={() => {
                              setShowQuitModal(true);
                              setAction("Quit");
                              setSelectedProgramID(goal.goal_id);
                              // handleQuit(goal.goal_id)
                            }}
                          />
                          {goal.quitted ? null : (
                            <LinearButton
                              text={goal.paused ? "Resume" : "Pause"}
                              onClick={(e) => {
                                setShowPauseModal(true);
                                setAction(goal.paused ? "Resume" : "Pause");
                                setSelectedProgramID(goal.goal_id);
                                // handlePause(goal.goal_id, e.target.innerText);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      {showPauseModal && (
        <NewModal
          heading={<h5 className="text-center">{action} the program</h5>}
          body={
            <div>
              {/* <h5 className="text-center">{action} the program</h5> */}
              <div className="text-center mb-8 p-4">
                {action === "Pause"
                  ? "Please confirm to pause the program. You can resume the program at any time by revisiting this page and clicking on the Resume button"
                  : "Please confirm to resume the program."}
              </div>
              <div className="flex flex-row justify-around">
                <LinearButton text={action} onClick={handlePause} />
                <LinearButton
                  text="Cancel"
                  onClick={() => setShowPauseModal(false)}
                />
              </div>
            </div>
          }
          onClose={() => setShowPauseModal(false)}
        />
      )}
      {showQuitModal && (
        <NewModal
          heading={<h5 className="text-center">{action} the program</h5>}
          body={
            <div>
              {/* <h5 className="text-center">{action} the program</h5> */}
              <div className="text-center mb-8 p-4">
                Please confirm to quit the program. This action can't be undone.
              </div>
              <div className="flex flex-row justify-around">
                <LinearButton text={action} onClick={handleQuit} />
                <LinearButton
                  text="Cancel"
                  onClick={() => setShowQuitModal(false)}
                />
              </div>
            </div>
          }
          onClose={() => setShowQuitModal(false)}
        />
      )}
    </div>
  );
};
export default MyPrograms;
