import { Tooltip } from "@material-ui/core";
import ExploreIcon from "@mui/icons-material/Explore";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../../api";
import Table from "../../../../components/Table";

const AdminProgramList = () => {
  const [goalList, setGoalList] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const ExploreButton = ({ goal_id, static_day, program_name }) => {
    return (
      <button
        onClick={() => {
          const referrer = location.pathname;
          history.push("/admin/program/schedule", {
            goal_id: goal_id,
            static_day,
            program_name,
            referrer: referrer,
          });
        }}
      >
        <Tooltip title="Explore Goal Schedule" arrow placement="top">
          <svg
            className="w-8 h-8"
            style={{ color: "#2288EE", fill: "#2288EE" }}
            viewBox="0 0 20 20"
          >
            <ExploreIcon />
          </svg>
        </Tooltip>
      </button>
    );
  };

  useEffect(() => {
    async function getProgramList() {
      const { data: programList } = await API.get("program", "all");
      const allGoals = programList.flatMap((program) =>
        program.goals.map((goal) => ({
          ...goal,
          programName: program.name,
        }))
      );

      // Setting the goals with program name to state
      setGoalList(allGoals);
    }

    getProgramList();
  }, []);

  useEffect(() => {
    if (goalList.length === 0) return;
    const tableData = [];
    for (const goal of goalList) {
      // console.log("Goal Data", goal);
      const goalName = goal?.name;
      const programName = goal?.programName;
      const element = [
        goalName,
        programName,
        <div className="flex flex-row gap-4">
          {ExploreButton({
            goal_id: goal?.id,
            static_day: null,
            program_name: goal?.programName,
          })}
        </div>,
      ];
      tableData.push(element);
    }
    setTableHeader(["Goal", "Program", "Explore"]);
    setTableBody(tableData);
  }, [goalList]);
  return (
    <div>
      <Table header={tableHeader} body={tableBody} />
    </div>
  );
};

export default AdminProgramList;
