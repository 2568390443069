import React, { useState, useEffect } from "react";
import { API } from "../../../api";
import tip_bulb from "../../../assests/icons/tip_bulb.svg";
import delete_icon from "../../../assests/icons/delete_icon.svg";
import no_tips from "../../../assests/icons/no_tips.svg";
import useSWR, { mutate } from "swr";

const SavedTips = () => {
  const tipsUrl = "user-data/saved-tips";
  const { data: savedTips } = useSWR(tipsUrl);
  // const [savedTips, setSavedTips] = useState([]);

  // async function getSavedTips() {
  //   const { data } = await API.get("user-data", "saved-tips");
  //   setSavedTips(data);
  // }

  // useEffect(() => {
  //   getSavedTips();
  // }, []);

  const _unsaveTip = async (id) => {
    const { status } = await API.post(`user-data/unsave-tip/${id}`);
    if (status === 200) mutate(tipsUrl);
  };

  if (!savedTips || savedTips?.length === 0)
    return (
      <div className="flex flex-row justify-center items-center h-screen -my-32">
        <img src={no_tips} />
      </div>
    );

  return (
    <div
      style={{
        overflowY: "scroll",
        scrollBehavior: "smooth",
        maxHeight: "70vh",
      }}
      className="bg-white"
    >
      {savedTips?.map((tip, index) => {
        return (
          <div
            key={index}
            className="p-4"
            style={{
              borderBottomColor: "#e6e6e6",
              borderBottomStyle: "solid",
              borderBottomWidth: 1,
            }}
          >
            <div className="flex flex-row justify-between gap-4 items-center">
              <div className="flex flex-row items-center gap-4 body-medium-1">
                <img src={tip_bulb} />
                {tip.tip?.tip_text}
              </div>
              <div
                className="cursor-pointer w-1/6 flex flex-row justify-end"
                onClick={() => _unsaveTip(tip.tip?.id)}
              >
                <img src={delete_icon} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SavedTips;
