import React, { useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import PasswordField from "../../../components/PasswordField";
import { API } from "../../../api";
import { useStoreActions } from "easy-peasy";
import { useHistory } from "react-router";
import Alert from "@material-ui/lab/Alert";

const LoginAdmin = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [inProgress, toggleProgress] = useState(false);
  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const saveAdmin = useStoreActions((a) => a.admin.update);
  const history = useHistory();

  const _handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    toggleAlertVisibility(false);
  };

  const _handleSubmit = async () => {
    try {
      toggleProgress(true);

      const regexEmail = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@linear.live+$/, "gm");
      const emailValid = regexEmail.test(loginData.email);

      if(emailValid){



      const { data, status } = await API.post("admin/login", loginData);
      if (status === 200) {
        // localStorage.setItem("token", data.tokens.IdToken);
        localStorage.setItem("email", data.email);
        delete data["tokens"];
        saveAdmin(data);
        // history.push("/admin/dashboard");
        // history.push("/admin/dashboarddummy");
        history.push("/admin/customer");
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      }

    }else {
      setAlertMessage("Only Admins are allowed ");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
   } 
  }catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="shadow-xl p-36">
        <div className="text-center text-3xl">Admin Dashboard</div>
        <div
          style={{ marginTop: "10%", display: "flex", flexDirection: "column" }}
        >
          <InputLabel
            style={{
              fontFamily: "ClanOT-NarrowNews",
              fontSize: "30px",
              marginBottom: "20px",
            }}
          >
            Log in
          </InputLabel>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            name="email"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <PasswordField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            name="password"
            _handleChange={_handleChange}
            style={{ marginBottom: "20px" }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={_handleSubmit}
            style={{ padding: "20px" }}
            disabled={inProgress}
          >
            {inProgress ? <CircularProgress /> : "Login"}
          </Button>
          {alertVisible && (
            <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginAdmin;
