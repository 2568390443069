import React from "react";
import Lottie from "react-lottie-segments";

import LottieStreakFire from "../../../assests/icons/Lottie/streak_fire.json";
import LottieBookStack from "../../../assests/icons/Lottie/book_stack.json";

import useSWR from "swr";

const Achievements = () => {
  const xpPointsUrl = "user-data/total-points";
  const streakUrl = "user-data/get-streak";

  const { data: xpPoints } = useSWR(xpPointsUrl);
  const { data: streak } = useSWR(streakUrl);

  const lottieBookStacksOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieBookStack,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottieFireStreakOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieStreakFire,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <div className="p-6 my-6 bg-white mx-2 w-full rounded-lg shadow">
        <div className="flex flex-col sm:flex-row justify-between gap-4 flex-grow">
          <div
            style={{ backgroundColor: "#E9F3FD", borderRadius: "10px" }}
            className="flex-grow p-4"
          >
            <div className="flex flex-row justify-between items-center h-16">
              <div className="flex flex-row items-center gap-4">
                <div className="flex items-center justify-center w-16 h-16">
                  <Lottie
                    options={lottieBookStacksOptions}
                    speed={1}
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>

                <h6>Total XP</h6>
              </div>
              <h3 style={{ color: "#2288EE" }}>
                {xpPoints?.total_points || 0}
              </h3>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#E9F3FD", borderRadius: "10px" }}
            className="flex-grow p-4"
          >
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-4">
                <div className="w-14 h-16">
                  <Lottie options={lottieFireStreakOptions} speed={1} />
                </div>
                <h6>Streak</h6>
              </div>
              <h3 style={{ color: "#2288EE" }}>{streak?.task_streak}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
